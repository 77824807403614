import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import svgSprite from '@assets/svg/sprite.svg';
import GoogleTagManager from '@components/molecules/gtm';
import LoadIndicator from '@components/atoms/loadIndicator';
import Router from '@/modules/router';
import Toasts from '@components/atoms/toasts';
import Idle from '@components/atoms/idle';
import AuditTimer from '@components/atoms/auditTimer';
import Alertbanner from '@components/molecules/Alertbanner/Alertbanner';
import {connect} from 'react-redux';
import {createToast, removeToast} from '@/stores/toasts';
import AlertBox from '@components/atoms/alertBox';

const App = ({isLoading, toasts, pathname, me, isAuthenticated, location, createToast, isErrorAlert, removeToast}) => {

  const [state, updateState] = useState({
    displayModalRelaunch: false,
    OpenBanner: false,
    lastLocation: null,
    isAuthenticated: null,
  });
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const idconnectionInternetToast = 500;
  let connectionInternetToast = toasts.some((item) => item.id === idconnectionInternetToast);

  useEffect(() => {
    if (!connectionInternetToast) {
      let seenToasts = JSON.parse(localStorage.getItem('seenToasts') || '[]');
      let unseenToasts = me?.toasts
        ? me?.toasts.filter(
          (toast) =>
            !seenToasts.includes(toast.id) &&
            !toasts.map((t) => t.id).includes(toast.id)
        )
        : [];
      unseenToasts.map((toast) => createToast(toast));
    }
  }, [me, createToast]);

  useEffect(() => {
    if (me && me?.remindersLevel === 4) {
      if (location?.pathname !== state.lastLocation) {
        updateState({...state, lastLocation: location?.pathname});
      }
    }
  }, [me, location?.pathname, state.lastLocation]);

  useEffect(() => {
    updateState({...state, isAuthenticated: isAuthenticated});
  }, [isAuthenticated]);


  useEffect(() => {
    const onlineHandler = () => {
      setIsOnline(true);
      if (connectionInternetToast) {
        removeToast(idconnectionInternetToast, '');
        createToast({
          id: 200,
          message: 'Votre connection à internet a été restaurée',
          category: 3
        });
        setTimeout(() => {
          removeToast(200,'');
        }, 1500);
      }
    };
    const offlineHandler = () => {
      setIsOnline(false);
      createToast({
        id: idconnectionInternetToast,
        message: 'Vous n\'êtes plus connecté à internet, vous risquez de perdre tous les travaux en cours sur votre espace élève',
        category: 1
      });
    };
    window.addEventListener('online', onlineHandler);
    window.addEventListener('offline', offlineHandler);
    return () => {
      window.removeEventListener('online', onlineHandler);
      window.removeEventListener('offline', offlineHandler);
    };
  }, [isOnline]);

  return (
    <>
      <AuditTimer me={me && me} pathname={pathname} isAuthenticated={isAuthenticated}>
        <Idle>
          <GoogleTagManager/>
          <main data-testid="app_core">
            <SVG src={svgSprite} uniquifyIDs={false}/>
            {
              isErrorAlert &&
              <AlertBox
                type="alert"
                className="text-center"
                message="Une erreur est survenue. Merci de réessayer d'ici quelques instants."
              />
            }
            {me?.alert &&
              localStorage.getItem('alertbanner') !==
              me?.alert.id.toString() && <Alertbanner/>}
            <LoadIndicator isLoading={isLoading} size={75}/>
            <Toasts toasts={toasts}/>
            <Router state={state} pathname={pathname} updateState={updateState}/>
          </main>
        </Idle>
      </AuditTimer>
    </>
  );
};


App.propTypes = {
  toasts: PropTypes.array,
  isLoading: PropTypes.bool,
  error: PropTypes.any,
  catchedError: PropTypes.any,
  pathname: PropTypes.string,
  me: PropTypes.object,
  createToast: PropTypes.any,
  lastLocation: PropTypes.any,
  isAuthenticated: PropTypes.bool,
  maintenance: PropTypes.bool,
  isErrorAlert: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    me: state.me,
    toasts: state.toasts,
    isLoading: state.isLoading,
    error: state.error,
    isAuthenticated: state.auth?.isAuthenticated,
    positioningState: state.auth?.positioningState,
    maintenance: state.auth.maintenance,
    isErrorAlert: state.auth.isErrorAlert,
  };
};

export default connect(mapStateToProps, {createToast, removeToast})(App);
