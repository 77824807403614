import React , {useState , useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import LazyImage from '@components/atoms/lazyImage';
import fr from '@/lang/fr';
import imgUrl from '@/utils/imgUrl';
import {changePicture , deletePicture , changeMe , getMe} from '@/stores/users';
import {check} from '@/stores/me';
import {getCountries} from '@/stores/countries';
import formatDate from '@/utils/formatDate';
import Input from '@components/atoms/input';
import {connect} from 'react-redux';
import AlertBox from '@components/atoms/alertBox';
import { isMailFormatValue } from '@/utils/checkInputFormat/isMailFormatValue';

const Account = (props) => {
  const {
    me ,
    getCountries ,
    countries ,
    goToNextSection ,
    changeMe ,
    getMe ,
    codeIsoList ,
    deletePicture ,
    changePicture ,
    check ,
  } = props;

  const [genderValue , setGender] = useState();
  const [countryValue , setCountryValue] = useState();
  const [phonesObject , SetPhonesObject] = useState({1: '' , 2: '' , 3: ''});
  const [values , setValues] = useState({});
  const [canSubmit, setCanSubmit] = useState(true);

  useEffect(() => {
    me && me?.gender && setGender(genderValue ?? me?.gender);
  } , [me]);

  const formRef = useRef(null);
  const [fileButtonLabel , updateFileButtonLabel] = useState('Modifier la photo');
  const [canSubmitPicture , updateSubmitPictureAbility] =
    useState(false);
  const [picture , setPicture] = useState(null);

  useEffect(() => {
    getCountries();
  } , [getCountries]);

  const [familySituationId , setFamilySituationId] = useState();
  const [phonesArray , setValuePhoneArray] = useState([]);

  useEffect(() => {
    getCountries();
  } , [getCountries]);

  const [showAlertError, setShowAlertError] = useState(false);

  useEffect(() => {
    if (me?.phones) {
      setValuePhoneArray(me?.phones);
      me?.phones.map((phone, index) => {
        if (phone.length > 0) {
          phonesObject[index + 1] = phone;
          setShowAlertError(false);
        }
      });
    }
  } , [me]);

  const [placeholderObject , SetPlaceholderObject] = useState({
    1: '' ,
    2: '' ,
    3: '' ,
  });

  const checkIfCanSubmit = (formData) => {
    const mandatoryFileds = ['email', 'address', 'postalCode', 'city'];
    return isMailFormatValue(formData?.email) &&
        Object.entries(formData).filter(x => mandatoryFileds.includes(x[0])).every(value => value[1] !== '');
  };

  const getAllformData = () => {
    const formElements = formRef.current.elements;
    const formData = Array.from(formElements).reduce((acc, element) => {
      if (element.tagName === 'INPUT') {
        acc[element.name] = element.value;
      }
      return acc;
    }, {});
    return formData;
  };

  const handleOnChange = () => {
    let formData = getAllformData();
    setCanSubmit(checkIfCanSubmit(formData));
  };
  useEffect(() => {
    let haveOnePhoneNotEmpty = Object.values(phonesObject).some(phone => (phone !== '' && phone !== undefined));
    let formData = formRef?.current ? getAllformData() : false;
    setCanSubmit(checkIfCanSubmit(formData) && haveOnePhoneNotEmpty);
  }, [phonesObject]);

  const onFileChange = (files) => {
    let newLabel = 'Modifier la photo';

    if (files.length > 0) {
      newLabel = files[0].name;
      setPicture(files[0]);
    }
    updateSubmitPictureAbility(files.length > 0 ? true : false);
    updateFileButtonLabel(newLabel);
  };

  const onSubmitPicture = (e) => {
    e.preventDefault();
    if (picture) {
      me?.image
        ? deletePicture().then((res) => {
          changePicture(picture).then(() => check());
        })
        : changePicture(picture).then(() => check());

      document.querySelector('#user-picture').value = null;
      updateSubmitPictureAbility(false);
      updateFileButtonLabel('Modifier la photo');
    }
  };

  useEffect(() => {
    if (me?.familySituationId) {
      setFamilySituationId(me?.familySituationId);
    }
  } , [me?.familySituationId]);

  useEffect(() => {
    let formData = formRef?.current ? getAllformData() : false;
    setCanSubmit(checkIfCanSubmit(formData) && phonesArray.length > 0);
  }, [me, phonesArray]);

  const handleOnSubmit = () => {
    const valuePhone1 = Object.values(phonesObject)[0]
      ? Object.values(phonesObject)[0]
      : null;
    const valuePhone2 = Object.values(phonesObject)[1]
      ? Object.values(phonesObject)[1]
      : me?.phones[1] || null;
    const valuePhone3 = Object.values(phonesObject)[2]
      ? Object.values(phonesObject)[2]
      : me?.phones[2] || null;

    let ArrayPhoneNumbers = [];

    ArrayPhoneNumbers.push(valuePhone1 , valuePhone2 , valuePhone3);

    let data = {
      gender: values?.gender || me?.gender ,
      firstName: values?.firstName || me?.firstName ,
      lastName: values?.lastName || me?.lastName ,
      familySituationId: values?.familySituation || familySituationId ,
      email: values?.email || me?.email ,
      address: values?.address || me?.location?.address ,
      postalCode: values?.postalCode || me?.location?.postalCode ,
      city: values?.city || me?.location?.city ,
      countryId: values?.country || me?.location?.country ,
      lastDegreeSpeciality: values?.lastDegreeSpeciality || me?.lastDegreeSpeciality ,
      phones: ArrayPhoneNumbers.filter((v) => v) ,
    };
    if(ArrayPhoneNumbers.every(x=>x===null)){
      setShowAlertError(true);
    } else {
      setShowAlertError(false);
      data && changeMe(data)
        .then(() => getMe())
        .then(() => goToNextSection());
    }

  };

  return (
    <React.Fragment>
      <div className="onboarding__scroller__container mgb-1">
        <div className="onboarding__scroller">
          <div className="onboarding__scroller__shadow"></div>
          <p>Confirmer vos informations afin de valider votre dossier.</p>
          <div>
            <div className="row">
              <LazyImage
                src={imgUrl({
                  fileName: me?.image ? me?.image.fileName : null ,
                  width: '80' ,
                  height: '80' ,
                })}
                alt={me?.username}
                width={80}
                height={80}
                className="round mgr-1"
              />
              <form
                onSubmit={e => onSubmitPicture(e)}
                id="user-picture_form"
                className="flex sm-layout-column md-layout-row sm-align-start-start md-align-start-center layout-wrap"
              >
                <input
                  type="file"
                  name="user-picture"
                  id="user-picture"
                  className="hide"
                  onChange={(e) => onFileChange(e.target.files)}
                />
                <label
                  htmlFor="user-picture"
                  className="button primary outline small md-mgr-1 mgb-0"
                >
                  {fileButtonLabel}
                </label>
                {canSubmitPicture && (
                  <button type="submit" className="button primary small mgv-0_5">
                    Uploader l'image
                  </button>
                )}
              </form>
            </div>
            <form className="mgt-1 flex align-start-center layout-column" ref={formRef} onChange={handleOnChange}>
              <div style={{gap: 20}} className="flex size-100p layout-wrap md-layout-nowrap">
                <label className="size-50p">
                  <span>{fr.profile.form.gender}</span>
                  <select name="gender" required={fr.input.required} value={genderValue ? genderValue : ''} onChange={e => {setGender(e.target.value); setValues(prevState => ({...prevState , [e.target.name]: e.target.value}));}}>
                    <option disabled value="">-</option>
                    <option value="M.">M.</option>
                    <option value="Mme">Mme</option>
                    <option value="Mlle">Mlle</option>
                  </select>
                </label>
                <label className="size-50p">
                  <span>{fr.profile.form.family_situation}</span>
                  <select name="familySituation" value={familySituationId} onChange={e => {
                    setFamilySituationId(e.target.value);
                  }}>
                    <option disabled value="-1">-</option>
                    {
                      me?.familySituations && me?.familySituations.map((situation , situationIndex) =>
                        <option key={situationIndex} value={situation.id}>{situation.name}</option>
                      )
                    }
                  </select>
                </label>
              </div>
              <div style={{gap: 20}} className="flex size-100p layout-wrap md-layout-nowrap">
                <label className="size-50p">
                  {fr.profile.form.lastName}
                  <input
                    type="text"
                    name="lastName"
                    className="size-100p"
                    required={fr.input.required}
                    defaultValue={me?.lastName}
                    readOnly
                  />
                </label>
                <label className="size-50p">
                  {fr.profile.form.firstName}
                  <input
                    type="text"
                    name="firstName"
                    className="size-100p"
                    required={fr.input.required}
                    defaultValue={me?.firstName}
                    readOnly
                  />
                </label>
              </div>
              <div style={{gap: 20}} className="flex size-100p layout-wrap md-layout-nowrap">
                <label className="size-50p">
                  {fr.profile.form.maidenName}
                  <input
                    type="text"
                    name="maidenName"
                    className="size-100p"
                    required={fr.input.required}
                    defaultValue={me?.maidenName}
                    readOnly
                  />
                </label>
                <label className="size-50p">
                  {fr.profile.form.birthDate}
                  <input
                    type="text"
                    name="birthDate"
                    className="size-100p"
                    required={fr.input.required}
                    defaultValue={me?.birthDate && formatDate(me?.birthDate , 'dd/MM/yyyy')}
                    readOnly
                  />
                </label>
              </div>
              <div className="flex size-100p layout-wrap md-layout-nowrap">
                <label className="size-100p">
                  {fr.profile.form.email}
                  <input
                    type="email"
                    name="email"
                    className="size-100p"
                    onChange={e => setValues(prevState => ({...prevState , [e.target.name]: e.target.value}))}
                    required={fr.input.required}
                    defaultValue={me?.email}
                  />
                </label>

              </div>
              <div style={{gap: 20}} className="flex align-start-start size-100p layout-wrap md-layout-nowrap">
                <label className="size-50p">
                  {fr.profile.form.subscriptionDate}
                  <input
                    type="text"
                    name="subscriptionDate"
                    className="size-100p "
                    required={fr.input.required}
                    defaultValue={me?.subscriptionDate && formatDate(me?.subscriptionDate , 'dd/MM/yyyy')}
                    readOnly
                  /></label>
                <label className="size-50p">
                  {fr.profile.form.lastDegreeSpeciality}
                  <input
                    type="text"
                    name="lastDegreeSpeciality"
                    onChange={e => setValues(prevState => ({...prevState , [e.target.name]: e.target.value}))}
                    className="size-100p"
                    label={fr.profile.form.lastDegreeSpeciality}
                    defaultValue={me?.lastDegreeSpeciality}
                  />
                </label>
              </div>
              <p
                className="x-small uppercase no-margin no-shadow text-gray-dark mgt-1_5 mgb-1 size-100p">Localisation</p>
              <div className="flex size-100p layout-wrap md-layout-nowrap">
                <label className="size-100p">
                  {fr.profile.form.address}
                  <input
                    type="text"
                    name="address"
                    className="md-mgr-1 size-100p"
                    onChange={e => setValues(prevState => ({...prevState , [e.target.name]: e.target.value}))}
                    required={fr.input.required}
                    defaultValue={me?.location?.address}
                  />
                </label>
              </div>
              <div style={{gap: 20}} className="flex size-100p layout-wrap md-layout-nowrap">
                <label className="size-50p">
                  {fr.profile.form.postalCode}
                  <input
                    type="text"
                    name="postalCode"
                    className="size-100p"
                    required={fr.input.required}
                    onChange={e => setValues(prevState => ({...prevState , [e.target.name]: e.target.value}))}
                    defaultValue={me?.location?.postalCode}
                  />
                </label>
                <label className="size-50p">
                  {fr.profile.form.city}
                  <input
                    type="text"
                    name="city"
                    className="size-100p"
                    label={fr.profile.form.city}
                    required={fr.input.required}
                    onChange={e => setValues(prevState => ({...prevState , [e.target.name]: e.target.value}))}
                    defaultValue={me?.location?.city}
                  />
                </label>
              </div>
              <label className="size-100p">
                <span>{fr.profile.form.country}</span>
                <select name="country" required={fr.input.required} value={countryValue} onChange={e => {
                  setCountryValue(e.target.value);
                }}>
                  <option disabled value="">-</option>
                  {
                    countries && countries.map((country , countryIndex) =>
                      <option key={countryIndex} value={country.code_iso}>{country.name}</option>
                    )
                  }
                </select>
              </label>
              {showAlertError &&
                <div>
                  <AlertBox type="alert" message="Veuillez entrer au moins un numéro de téléphone"/>
                </div>}
              <p className="x-small uppercase no-margin no-shadow text-gray-dark mgt-1_5 mgb-1 size-100p">Téléphone</p>
              <div className="flex layout-column size-100p layout-wrap md-layout-nowrap">
                <Input
                  type="tel"
                  name={'phone1'}
                  placeholder={Object.values(placeholderObject)[0]}
                  placeholderObject={placeholderObject}
                  SetPlaceholderObject={SetPlaceholderObject}
                  className="md-mgr-1 size-100p md-size-33p"
                  label={fr.profile.form.phone1}
                  required={fr.input.required}
                  SetPhonesObject={(e)=> {
                    SetPhonesObject(e);
                    setShowAlertError(false);
                  }}
                  phonesObject={phonesObject}
                  phoneValue={phonesArray[0]}
                  preferredCountries={codeIsoList}
                  defaultValue={me?.phones && me?.phones[0]}
                  onChange = {(e)=>e.target.value!==null ? setShowAlertError(false):''}
                />
                <Input
                  type="tel"
                  name={'phone2'}
                  placeholder={Object.values(placeholderObject)[1]}
                  placeholderObject={placeholderObject}
                  SetPhonesObject={(e)=> {
                    SetPhonesObject(e);
                    setShowAlertError(false);
                  }}
                  phonesObject={phonesObject}
                  SetPlaceholderObject={SetPlaceholderObject}
                  phoneValue={phonesArray[1]}
                  className="md-mgl-1 md-mgr-1 size-100p md-size-33p"
                  label={fr.profile.form.phone2}
                  preferredCountries={codeIsoList}
                  defaultValue={me?.phones && me?.phones[1]}
                  onChange = {(e)=>e.target.value!==null ? setShowAlertError(false):''}
                />
                <Input
                  type="tel"
                  name={'phone3'}
                  placeholder={Object.values(placeholderObject)[2]}
                  SetPlaceholderObject={SetPlaceholderObject}
                  placeholderObject={placeholderObject}
                  SetPhonesObject={(e) => {
                    SetPhonesObject(e);
                    setShowAlertError(false);
                  }}
                  phonesObject={phonesObject}
                  phoneValue={phonesArray[2]}
                  className="md-mgl-1 size-100p md-size-33p"
                  label={fr.profile.form.phone3}
                  preferredCountries={codeIsoList}
                  defaultValue={me?.phones && me?.phones[2]}
                  onChange={(e) => e.target.value !== null ? setShowAlertError(false) : ''}
                />

              </div>
            </form>
          </div>
          <div className="onboarding__scroller__shadow"></div>
        </div>
      </div>
      <button onClick={() => handleOnSubmit()} disabled={!canSubmit} className="button primary md-mgb-0">Valider</button>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    me: {
      ...state.me ,
      ...state.users.me ,
    } ,
    infosHasChanged: state.users.infosHasChanged ,
    pictureHasChanged: state.users.pictureHasChanged ,
    countries: state.countries ,
  };
};

Account.propTypes = {
  me: PropTypes.object ,
  onFileChange: PropTypes.func ,
  fileButtonLabel: PropTypes.string ,
  canSubmitInfos: PropTypes.bool ,
  onFormRef: PropTypes.func ,
  onSubmitInfos: PropTypes.func ,
  submitForm: PropTypes.func ,
  goToNextSection: PropTypes.func ,
  changeMe: PropTypes.func ,
  getMe: PropTypes.func ,
  canSubmitPicture: PropTypes.bool ,
  onSubmitPicture: PropTypes.func ,
  infosHasChanged: PropTypes.bool ,
  pictureHasChanged: PropTypes.bool ,
  countries: PropTypes.array ,
  codeIsoList: PropTypes.array ,
  getCountries: PropTypes.any ,
  deletePicture: PropTypes.any ,
  changePicture: PropTypes.any ,
  check: PropTypes.any ,
};

export default connect(mapStateToProps , {
  changePicture ,
  deletePicture ,
  check ,
  changeMe ,
  getMe ,
  getCountries ,
})(Account);
